@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
@import "@/app/assets/style/variables";

.tabs {

  &_head {
    overflow: auto;
    position: relative;
    user-select: none;
    -ms-overflow-style: none;
    scrollbar-width: none; 
    padding-bottom: 1px !important;
    &::-webkit-scrollbar { 
      display: none;
    }
    @media only screen and (max-width: $bp-phone) {
      margin: 0 -16px;
      padding: 0 16px;
    }
  }

  &_head-item {
    padding: 7px 19px;
    display: block;
    border: 1px solid transparent;
    font-family: $font-main-SB;
    font-size: 1.8rem;
    line-height: 1.3;
    color: var(--gray-light);
    transition: all 0.3s;
    border-radius: 90px;
    cursor: pointer;
    white-space: nowrap;
    @include desktopStyle {
      padding: get-vw(7px) get-vw(19px);
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.6rem;
      padding: 5px 23px;
    }
    &:hover {
      color: var(--blue);
    }
    &.b-active {
      border-color: var(--blue);
      color: var(--blue);
    }
  }

  &_content {
    margin-top: 32px;
    @include desktopStyle {
      margin-top: get-vw(32px);
    }
    @media only screen and (max-width: $bp-phone) {
      margin-top: 24px;
    }
  }
}