@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.head {
  background: var(--blue-light);
  padding-bottom: 120px;
  @include desktopStyle {
    padding-bottom: get-vw(120px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-bottom: 80px;
    :global(.breadcrumbs) {
      padding-top: 0;
      padding-bottom: 16px;
    }
  }
  @media only screen and (max-width: $bp-phone) {
    padding-bottom: 40px;
  }

  &--with-img {
    h1,
    &_text {
      padding-right: 304px;
      @include desktopStyle {
        padding-right: get-vw(304px);
      }
      @media only screen and (max-width: $bp-tablet) {
        padding-right: 152px;
      }
    }
  }

  &_text {
    color: var(--gray);
    max-width: 780px;
    margin-top: 16px;
    @include desktopStyle {
      margin-top: get-vw(16px);
      max-width: get-vw(780px);
    }
  }

  &_list {
    position: relative;
    margin-top: 70px;
    gap: 12px;
    grid-gap: 12px;
    @include desktopStyle {
      margin-top: get-vw(70px);
      gap: get-vw(12px);
      grid-gap: get-vw(12px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 32px;
    }
    @media only screen and (max-width: $bp-phone) {
      display: block;
    }

    &-img {
      position: absolute;
      bottom: 100%;
      right: 92px;
      width: 195px;
      height: 165px;
      object-fit: contain;
      object-position: right bottom;
      @include desktopStyle {
        right: get-vw(92px);
        width: get-vw(195px);
        height: get-vw(165px);
      }
      @media only screen and (max-width: $bp-tablet) {
        right: 34px;
        width: 102px;
        height: 86.3px;
      }
    }

    &-col {
      width: percent-width-flex-items(2, 12px);
      @include desktopStyle {
        width: percent-width-flex-items(2, get-vw(12px));
      }
      @media only screen and (max-width: $bp-phone) {
        width: 100%;
        display: block;
      }

      &:not(:last-child) {
        @media only screen and (max-width: $bp-phone) {
          margin-bottom: 12px;
        }
      }
    }

    &-item {
      width: 100%;
      flex: 1;
      color: var(--gray);
      background: var(--white);
      border-radius: 12px;
      padding: 32px;
      @include desktopStyle {
        padding: get-vw(32px);
      }
      @media only screen and (max-width: $bp-tablet) {
        padding: 24px;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
        @include desktopStyle {
          margin-bottom: get-vw(12px);
        }
      }

      &-title {
        color: var(--gray-dark);
        font-family: $font-main-MD;
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.6;
        margin-bottom: 16px;
        @include desktopStyle {
          margin-bottom: get-vw(16px);
        }
        @media only screen and (max-width: $bp-tablet) {
          font-size: 1.5rem;
          margin-bottom: 12px;
        }
      }

      &-phone {
        &:not(:last-child) {
          margin-bottom: 24px;
          @include desktopStyle {
            margin-bottom: get-vw(24px);
          }
          @media only screen and (max-width: $bp-tablet) {
            margin-bottom: 12px;
          }
        }

        &-item {
          a {
            gap: 8px;
            grid-gap: 8px;
            color: inherit;
            text-decoration: none;
            line-height: 1.3;
            @include desktopStyle {
              gap: get-vw(8px);
              grid-gap: get-vw(8px);
            }
            @media only screen and (max-width: $bp-tablet) {
              font-size: 2.4rem;
            }
          }
          svg {
            color: inherit;
            width: 28px;
            height: 28px;
            flex-shrink: 0;
            @include desktopStyle {
              width: get-vw(28px);
              height: get-vw(28px);
            }
            @media only screen and (max-width: $bp-tablet) {
              width: 24px;
              height: 24px;
            }
          }
        }
        &-descr {
          font-family: $font-main-MD;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.6;
          margin-top: 8px;
          @include desktopStyle {
            margin-top: get-vw(8px);
          }
          @media only screen and (max-width: $bp-tablet) {
            font-size: 1.4rem;
            margin-top: 4px;
          }
        }
      }

      &-contacts {
        &-item {
          &:not(:last-child) {
            margin-bottom: 12px;
            @include desktopStyle {
              margin-bottom: get-vw(12px);
            }
          }
          &-link {
            color: inherit;
            font-family: $font-main-MD;
            font-size: 2rem;
            font-weight: 500;
            line-height: 1.6;
            text-decoration: none;
            gap: 12px;
            grid-gap: 12px;
            @include desktopStyle {
              gap: get-vw(12px);
              grid-gap: get-vw(12px);
            }
            @media only screen and (max-width: $bp-tablet) {
              font-size: 1.5rem;
            }

            &[href]:hover {
              color: var(--blue);
            }
          }
          svg {
            width: 24px;
            height: 24px;
            margin-top: 4px;
            flex-shrink: 0;
            @include desktopStyle {
              width: get-vw(24px);
              height: get-vw(24px);
              margin-top: get-vw(4px);
            }
            @media only screen and (max-width: $bp-tablet) {
              width: 16px;
              height: 16px;
            }
          }
        }
        &-onmap {
          display: flex;
          padding-left: 36px;
          @include desktopStyle {
            padding-left: get-vw(36px);
          }
          @media only screen and (max-width: $bp-tablet) {
            padding-left: 28px;
          }

          :global(.link-arrow) {
            color: var(--blue);
            gap: 8px;
            grid-gap: 8px;
            font-size: 1.8rem;
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
            @include desktopStyle {
              gap: get-vw(8px);
              grid-gap: get-vw(8px);
            }
            @media only screen and (max-width: $bp-tablet) {
              font-size: 1.4rem;
            }

            svg {
              width: 16px;
              height: 16px;
              @include desktopStyle {
                width: get-vw(16px);
                height: get-vw(16px);
              }
              @media only screen and (max-width: $bp-tablet) {
                width: 12px;
                height: 12px;
              }
            }

            &:hover {
              svg {
                transform: translateX(5px);
              }
            }
          }
        }
      }

      $colors:
        'blue' var(--blue),
        'pink_dark' var(--bg-pink-dark);
      @each $name, $bgBlock in $colors {
        &.theme-#{$name} {
          .head_list-item-phone-item,
          .head_list-item-contacts-item svg {
            color: $bgBlock;
          }
        }
      }
    }
  }
}