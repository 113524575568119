@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.head {
  background: var(--blue-light);
  padding-bottom: 80px;
  @include desktopStyle {
    padding-bottom: get-vw(80px);
  }
  @media only screen and (max-width: $bp-tablet) {
    :global(.breadcrumbs) {
      padding-top: 0;
      padding-bottom: 16px;
    }
  }
  @media only screen and (max-width: $bp-phone) {
    padding-bottom: 40px;
  }

  &_text {
    color: var(--gray);
    max-width: 780px;
    margin-top: 16px;
    @include desktopStyle {
      margin-top: get-vw(16px);
      max-width: get-vw(780px);
    }
  }
}