@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
@import "@/app/assets/style/variables";

.info-steps {
  padding: 120px 0;
  @include desktopStyle {
    padding-top: get-vw(120px);
    padding-bottom: get-vw(120px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media only screen and (max-width: $bp-phone) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &.bg-pink {
    .info-steps_label {
      background: var(--pink-light);
      color: var(--pink);
      &::after {
        background: var(--bg-pink-dark);
      }
    }
    .info-steps_steps {
      background: var(--pink-light);
    }
    .info-steps_subtitle {
      color: var(--pink);
    }
    .info-steps_block-icon {
      color: var(--bg-pink-dark);
    }
  }

  &_top {
    @media only screen and (max-width: $bp-tablet) {
      display: block;
    }

    &-left {
      margin-bottom: 100px;
      @include desktopStyle {
        margin-bottom: get-vw(100px);
      }
      @media only screen and (max-width: $bp-tablet) {
        margin-bottom: 70px;
      }
      @media only screen and (max-width: $bp-phone) {
        margin-bottom: 40px;
      }
    }
    &-img {
      @media only screen and (max-width: $bp-tablet) {
        margin-bottom: 48px;
      }
      @media only screen and (max-width: $bp-phone) {
        margin-bottom: 24px;
      }

      img {
        display: block;
        max-width: 300px;
        width: auto;
        height: auto;
        @include desktopStyle {
          max-width: get-vw(300px);
        }
        @media only screen and (max-width: $bp-tablet) {
          margin: 0 auto;
        }
      }

      &.mob-size-width_128px {
        img {
          @media only screen and (max-width: $bp-tablet) {
            max-width: 128px;
          }
        }
      }
    }

    &--above_steps {
      .info-steps_top-img {
        margin-top: auto;
        margin-right: 116px;
        margin-bottom: 0;
        @include desktopStyle {
          margin-right: get-vw(116px);
        }
        @media only screen and (max-width: $bp-tablet) {
          margin-right: 0;
        }
      }
    }
    &--above_steps_overflow {
      .info-steps_top-img {
        margin-top: auto;
        margin-right: 202px;
        margin-bottom: 0;
        @include desktopStyle {
          margin-right: get-vw(202px);
        }
        @media only screen and (max-width: $bp-tablet) {
          margin-right: 0;
        }

        img {
          margin-bottom: -20px;
          @include desktopStyle {
            margin-bottom: get-vw(-20px);
          }
          @media only screen and (max-width: $bp-tablet) {
            margin-bottom: -8px;
            margin-right: 48px;
          }
          @media only screen and (max-width: $bp-phone) {
            margin-bottom: 0;
            max-width: 75px;
          }
        }
      }
    }
    &--above_steps_overflow_secondary {
      .info-steps_top-img {
        margin-top: auto;
        margin-right: 106px;
        margin-bottom: 0;
        @include desktopStyle {
          margin-right: get-vw(106px);
        }
        @media only screen and (max-width: $bp-tablet) {
          margin-right: 0;
        }

        img {
          @media only screen and (max-width: $bp-tablet) {
            margin-left: auto;
            margin-right: auto;
          }
          @media only screen and (max-width: $bp-phone) {
            max-width: 75px;
          }
        }
      }
    }
  }

  &_title {
    max-width: 814px;
    @include desktopStyle {
      max-width: get-vw(814px);
    }
    @media only screen and (max-width: $bp-tablet) {
      max-width: none;
    }
  }

  &_text {
    max-width: 814px;
    color: var(--gray);
    margin-top: 32px;
    @include desktopStyle {
      margin-top: get-vw(32px);
      max-width: get-vw(814px);
    }
    @media only screen and (max-width: $bp-tablet) {
      max-width: none;
      margin-top: 24px;
    }
    @media only screen and (max-width: $bp-phone) {
      margin-top: 16px;
    }
  }

  &_label {
    display: inline-flex;
    vertical-align: middle;
    position: relative;
    align-items: center;
    margin-top: 32px;
    border-radius: 40px;
    padding: 6px 20px;
    background: var(--blue-light);
    font-family: $font-main-SB;
    color: var(--white);
    gap: 12px;
    grid-gap: 12px;
    @include desktopStyle {
      margin-top: get-vw(32px);
      border-radius: get-vw(40px);
      padding: get-vw(6px) get-vw(20px);
      gap: get-vw(12px);
      grid-gap: get-vw(12px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 24px;
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.6rem;
      padding: 3px 16px;
      gap: 8px;
      grid-gap: 8px;
      line-height: 1.5;
      margin-top: 16px;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      background: var(--blue);
      border: 2px solid var(--white);
      width: 12px;
      height: 12px;
      right: 0;
      top: 0;
      border-radius: 100%;
      @include desktopStyle {
        width: get-vw(12px);
        height: get-vw(12px);
        border-width: get-vw(2px);
      }
      @media only screen and (max-width: $bp-phone) {
        width: 10px;
        height: 10px;
      }
    }
  }

  &_label-icon {
    color: var(--bg-pink-dark);
    @include desktopStyle {
      width: get-vw(14px);
      height: get-vw(24px);
    }
    @media only screen and (max-width: $bp-phone) {
      width: 9px;
      height: 16px;
    }
  }

  &_content {
    @media only screen and (max-width: $bp-tablet) {
      // margin-top: 70px;
    }
    @media only screen and (max-width: $bp-phone) {
      // margin-top: 40px;
    }
  }

  &_steps {
    background: var(--blue-light);
    border-radius: 12px;
    padding: 64px;
    gap: 100px 136px;
    grid-gap: 100px 136px;
    @include desktopStyle {
      padding: get-vw(64px);
      gap: get-vw(100px) get-vw(136px);
      grid-gap: get-vw(100px) get-vw(136px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 24px;
      gap: 72px;
      grid-gap: 72px;
    }
  }

  &_content-top {
    margin-bottom: 32px;
    gap: 8px 16px;
    grid-gap: 8px 16px;
    @include desktopStyle {
      margin-bottom: get-vw(32px);
      gap: get-vw(8px) get-vw(16px);
      grid-gap: get-vw(8px) get-vw(16px);
    }
    @media only screen and (max-width: $bp-phone) {
      flex-direction: column;
      margin-bottom: 24px;
      align-items: flex-start;
    }
  }

  &_content-icon {
    max-width: 104px;
    @include desktopStyle {
      max-width: get-vw(104px);
    }
    @media only screen and (max-width: $bp-phone) {
      max-width: 72px;
    }
    img {
      display: block;
    }
  }

  &_block {
    position: relative;
    width: calc(33.333% - 91px);
    @include desktopStyle {
      width: calc(33.333% - get-vw(91px));
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      &:nth-child(3n + 1) {
        .info-steps_block-icon {
          display: block;
        }
      }
    }
    &:first-child,
    &:nth-child(3n + 1) {
      .info-steps_block-icon {
        display: none;
      }
    }
    :global(.btn) {
      margin-top: 20px;
      gap: 8px;
      grid-gap: 8px;
      align-items: center;
      @include desktopStyle {
        gap: get-vw(8px);
        grid-gap: get-vw(8px);
        margin-top: get-vw(20px);
      }
      @media only screen and (max-width: $bp-phone) {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      svg {
        width: 16px;
        height: 16px;
        @include desktopStyle {
          width: get-vw(16px);
          height: get-vw(16px);
        }
        @media only screen and (max-width: $bp-phone) {
          width: 20px;
          height: 20px;
        }
      }
    }
    :global(.bg-pink) {
      padding-left: 18px;
      padding-right: 22px;
      @include desktopStyle {
        padding-left: get-vw(18px);
        padding-right: get-vw(22px);
      }
      @media only screen and (max-width: $bp-phone) {
        padding: 14px 30px 14px 22px;
      }
    }
  }

  &_block-icon {
    width: 48px;
    height: 48px;
    color: var(--blue);
    position: absolute;
    top: 12px;
    right: 100%;
    margin-right: 44px;
    @include desktopStyle {
      width: get-vw(48px);
      height: get-vw(48px);
      top: get-vw(12px);
      margin-right: get-vw(44px);
    }
    @media only screen and (max-width: $bp-tablet) {
      transform: rotate(90deg);
      right: unset;
      top: unset;
      bottom: 100%;
      margin-bottom: 20px;
      margin-right: 0;
      width: 32px;
      height: 32px;
    }
    svg {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &_block-img {
    display: block;
    width: 100%;
    max-width: 297px;
    height: auto;
    margin-bottom: 20px;
    @include desktopStyle {
      max-width: get-vw(297px);
      margin-bottom: get-vw(20px);
    }
    @media only screen and (max-width: $bp-tablet) {
      max-width: 182.46px;
    }
  }

  &_block-title {
    margin-bottom: 20px;
    @include desktopStyle {
      margin-bottom: get-vw(20px);
    }
  }

  &_block-text {
    color: var(--gray);
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.8rem;
    }
  }

  &--arr-center {
    .info-steps_block-icon {
      top: 50%;
      margin-top: -24px;
      @include desktopStyle {
        margin-top: get-vw(-24px);
      }
      @media only screen and (max-width: $bp-tablet) {
        top: unset;
        margin-top: 0;
      }
    }
  }
}
