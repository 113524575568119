@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="whiteBlack"],
[data-color-scheme="blackWhite"],
[data-color-scheme="blue"] {
  .document-link {
    background: transparent;
    border: 1px solid var(--gray-dark);
  }
}

[data-color-scheme="blackWhite"] {
  .document-link {
    &:hover {
      .document-link_icon {
        filter: invert(1);
      }
    }
  }
}

.document-link {
  background: var(--gray-bg);
  padding: 16px 16px 16px 84px;
  position: relative;
  color: var(--gray-dark);
  border-radius: 12px;
  transition: all 0.3s;
  text-decoration: none;
  font-size: 1.6rem;
  line-height: 1.5;
  min-height: 88px;
  display: block;
  font-family: $font-main-MD;
  transform: translateZ(0);
  @include desktopStyle {
    padding: get-vw(16px) get-vw(16px) get-vw(16px) get-vw(84px);
    min-height: get-vw(88px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding: 16px 20px 16px 52px;
    border-radius: 8px;
    font-size: 1.4rem;
    min-height: 1px;
  }
  &:hover {
    background: var(--blue);
    color: var(--white);
    .document-link_icon {
      animation: scaleIcon 0.7s ease-in-out;
      filter: brightness(0) invert(1);
    }
    .document-link_ext {
      color: var(--white);
    }
  }

  &_icon {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 20px;
    left: 16px;
    color: var(--gray-light);
    @include desktopStyle {
      width: get-vw(48px);
      height: get-vw(48px);
      top: get-vw(16px);
      left: get-vw(16px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 24px;
      height: 24px;
      top: 16px;
    }
  }

  &_ext {
    color: var(--gray-light);
    display: block;
    margin-top: 2px;
    transition: color 0.3s;
  }
}

@keyframes scaleIcon {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
