@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="blackWhite"] {
  .map-with-offices_infowindow {
    background: var(--bg-blue-light-2);
  }
}

.map-with-offices {
  padding: 80px 0;
  @include desktopStyle {
    padding: get-vw(80px) 0;
  }
  @media only screen and (max-width: $bp-tablet) {
    padding: 40px 0;
  }

  &_title {
    margin-bottom: 60px;
    @include desktopStyle {
      margin-bottom: get-vw(60px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-bottom: 24px;
    }
  }

  &_filter {
    gap: 24px;
    grid-gap: 24px;
    margin-bottom: 32px;
    @include desktopStyle {
      gap: get-vw(24px);
      grid-gap: get-vw(24px);
      margin-bottom: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      display: block;
      margin-bottom: 24px;
    }

    :global(.form_item) {
      width: 100%;
      max-width: 360px;
      margin-bottom: 0;
      @include desktopStyle {
        max-width: get-vw(360px);
      }
      @media only screen and (max-width: $bp-tablet) {
        max-width: none;
      }

      &:not(:last-child) {
        @media only screen and (max-width: $bp-tablet) {
          margin-bottom: 12px;
        }
      }
    }
  }

  &_map {
    height: 770px;
    border-radius: 12px;
    background: var(--gray-bg);
    position: relative;
    @include desktopStyle {
      height: get-vw(770px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-left: -16px;
      margin-right: -16px;
      height: 600px;
      border-radius: 0;
    }

    & > div:first-child {
      border-radius: 12px;
      overflow: hidden;
    }

    :global {
      .gm-style {
        .gm-style-iw-c {
          padding: 0;
          border-radius: 12px;
          box-shadow: none;
          max-width: none !important;
          & > button {
            display: none !important;
          }
        }
        .gm-style-iw-d {
          padding: 0;
          overflow: visible !important;
          max-height: none !important;
        }
        .gm-style-iw-chr,
        .gm-style-iw-tc {
          display: none;
        }
      }
      img {
        opacity: 1 !important;
      }
    }
  }

  &_infowindow {
    padding: 32px;
    width: 100%;
    max-width: 376px;
    position: relative;
    @include desktopStyle {
      padding: get-vw(32px);
      max-width: get-vw(376px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 24px 16px;
      max-width: 249px;
    }

    &-close {
      border: none;
      background: none;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      padding: 16px;
      color: var(--gray-light);
      transition: color .3s;
      @include desktopStyle {
        padding: get-vw(16px);
      }
      @media only screen and (max-width: $bp-tablet) {
        padding: 12px;
      }

      &:hover {
        color: var(--blue);
      }
      svg {
        width: 16px;
        height: 16px;
        @include desktopStyle {
          width: get-vw(16px);
          height: get-vw(16px);
        }
      }
    }
    &-title {
      font-family: $font-main-SB;
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 1.5;
      color: var(--gray-dark);
      @media only screen and (max-width: $bp-tablet) {
        font-size: 1.6rem;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
        @include desktopStyle {
          margin-bottom: get-vw(16px);
        }
        @media only screen and (max-width: $bp-tablet) {
          margin-bottom: 12px;
        }
      }
    }

    &-contacts {
      &-item {
        color: var(--gray);
        &:not(:last-child) {
          margin-bottom: 12px;
          @include desktopStyle {
            margin-bottom: get-vw(12px);
          }
          @media only screen and (max-width: $bp-tablet) {
            margin-bottom: 8px;
          }
        }
        &-link {
          font-family: $font-main-MD;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.6;
          color: inherit;
          text-decoration: none;
          gap: 8px;
          grid-gap: 8px;
          @include desktopStyle {
            gap: get-vw(8px);
            grid-gap: get-vw(8px);
          }
          @media only screen and (max-width: $bp-tablet) {
            font-size: 1.4rem;
          }

          svg {
            color: var(--blue);
            flex-shrink: 0;
            width: 16px;
            height: 16px;
            margin-top: 5px;
            @include desktopStyle {
              width: get-vw(16px);
              height: get-vw(16px);
              margin-top: get-vw(5px);
            }
            @media only screen and (max-width: $bp-tablet) {
              margin-top: 3px;
            }
          }

          &[href]:hover {
            color: var(--blue);
          }
        }
        &--phone {
          color: var(--blue);
        }
      }
    }
  }

  &_legends {
    position: absolute;
    top: 22px;
    right: 22px;
    @include desktopStyle {
      top: get-vw(22px);
      right: get-vw(22px);
    }

    button {
      color: var(--gray-dark);
      background-color: var(--white);
      cursor: pointer;
      border: none;
      border-radius: 100%;
      transition: color .3s, background-color .3s;
      padding: 12px;
      @include desktopStyle {
        padding: get-vw(12px);
      }
      @media only screen and (max-width: $bp-tablet) {
        padding: 10px;
      }

      svg {
        width: 20px;
        height: 20px;
        @include desktopStyle {
          width: get-vw(20px);
          height: get-vw(20px);
        }
        @media only screen and (max-width: $bp-tablet) {
          width: 16px;
          height: 16px;
        }
      }

      &:hover {
        color: var(--blue);
      }

      &.is-active {
        color: var(--white);
        background-color: var(--blue);
      }
    }

    &-list {
      position: absolute;
      top: 100%;
      right: 0;
      background-color: var(--white);
      border-radius: 12px;
      overflow: auto;
      margin-top: 10px;
      width: 238px;
      max-height: 320px;
      @include desktopStyle {
        margin-top: get-vw(10px);
        width: get-vw(238px);
        max-height: get-vw(320px);
      }

      &-content {
        padding: 20px;
        @include desktopStyle {
          padding: get-vw(20px);
        }
      }
    }

    &-item {
      font-family: $font-main-MD;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.6;
      &:not(:last-child) {
        margin-bottom: 12px;
        @include desktopStyle {
          margin-bottom: get-vw(12px);
        }
      }
      span {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        margin-right: 12px;
        flex-shrink: 0;
        @include desktopStyle {
          width: get-vw(12px);
          height: get-vw(12px);
          margin-right: get-vw(12px);
        }
      }
    }
  }

  &_zoom {
    position: absolute;
    right: 22px;
    bottom: 22px;
    @include desktopStyle {
      right: get-vw(22px);
      bottom: get-vw(22px);
    }

    button {
      color: var(--gray);
      background-color: var(--white);
      cursor: pointer;
      border: none;
      border-radius: 100%;
      transition: color .3s;
      padding: 12px;
      @include desktopStyle {
        padding: get-vw(12px);
      }
      @media only screen and (max-width: $bp-tablet) {
        padding: 10px;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
        @include desktopStyle {
          margin-bottom: get-vw(12px);
        }
        @media only screen and (max-width: $bp-tablet) {
          margin-bottom: 6px;
        }
      }

      svg {
        width: 20px;
        height: 20px;
        @include desktopStyle {
          width: get-vw(20px);
          height: get-vw(20px);
        }
        @media only screen and (max-width: $bp-tablet) {
          width: 16px;
          height: 16px;
        }
      }

      &:hover {
        color: var(--blue);
      }
    }
  }

  &_list {
    margin-top: 60px;
    @include desktopStyle {
      margin-top: get-vw(60px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 32px;
    }

    &-item {
      padding: 24px 0;
      border-top: 1px solid var(--gray-second);
      @include desktopStyle {
        padding-top: get-vw(24px);
        padding-bottom: get-vw(24px);
      }
      @media only screen and (max-width: $bp-tablet) {
        display: block;
        padding: 20px 0;
      }

      &:last-child {
        border-bottom: 1px solid var(--gray-second);
      }

      &-col {
        flex: 0 0 22.5%; // 360px
        padding: 0 20px;
        @include desktopStyle {
          padding-left: get-vw(20px);
          padding-right: get-vw(20px);
        }
        @media only screen and (max-width: $bp-tablet) {
          padding: 0;
        }

        &--title {
          flex-basis: 33.75%; // 540px
          @media only screen and (max-width: $bp-tablet) {
            margin-bottom: 16px;
          }
        }
        &--contacts {}
        &--time {}
        &--address {
          flex-basis: 21.25%; // 340px
        }

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }

        & + .map-with-offices_list-item-col:not(.is-empty) {
          @media only screen and (max-width: $bp-tablet) {
            margin-top: 8px;
          }
        }
      }
    }

    &-title {
      line-height: 1.5;
      @media only screen and (max-width: $bp-tablet) {
        font-size: 2rem;
        line-height: 1.3;
      }
    }

    &-agent {
      color: var(--blue);
      font-family: $font-main-SB;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.3;
      border-radius: 90px;
      border: 1px solid var(--blue);
      margin-top: 12px;
      padding: 4px 12px;
      gap: 8px;
      grid-gap: 8px;
      @include desktopStyle {
        margin-top: get-vw(12px);
        padding: get-vw(4px) get-vw(12px);
        gap: get-vw(8px);
        grid-gap: get-vw(8px);
      }

      svg {
        color: inherit;
        width: 16px;
        height: 16px;
        @include desktopStyle {
          width: get-vw(16px);
          height: get-vw(16px);
        }
      }
    }

    &-contact {
      color: var(--gray);
      &:not(:last-child) {
        margin-bottom: 8px;
        @include desktopStyle {
          margin-bottom: get-vw(8px);
        }
      }
      &-link {
        font-family: $font-main-MD;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.6;
        color: inherit;
        text-decoration: none;
        gap: 8px;
        grid-gap: 8px;
        @include desktopStyle {
          gap: get-vw(8px);
          grid-gap: get-vw(8px);
        }
        @media only screen and (max-width: $bp-tablet) {
          font-size: 1.5rem;
        }

        svg {
          color: var(--blue);
          flex-shrink: 0;
          width: 16px;
          height: 16px;
          margin-top: 5px;
          @include desktopStyle {
            width: get-vw(16px);
            height: get-vw(16px);
            margin-top: get-vw(5px);
          }
          @media only screen and (max-width: $bp-tablet) {
            margin-top: 3px;
          }
        }

        &[href]:hover {
          color: var(--blue);
        }
      }

      &--lg {
        .map-with-offices_list-contact-link {
          font-size: 2rem;
          @media only screen and (max-width: $bp-tablet) {
            font-size: 1.5rem;
          }

          svg {
            width: 20px;
            height: 20px;
            margin-top: 6px;
            @include desktopStyle {
              width: get-vw(20px);
              height: get-vw(20px);
              margin-top: get-vw(6px);
            }
            @media only screen and (max-width: $bp-tablet) {
              width: 16px;
              height: 16px;
              margin-top: 3px;
            }
          }
        }
      }
    }

    &-btn {
      display: flex;
      padding-left: 24px;
      @include desktopStyle {
        padding-left: get-vw(24px);
      }
    }

    :global(.link-arrow) {
      color: var(--blue);
      gap: 8px;
      grid-gap: 8px;
      font-size: 1.4rem;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      @include desktopStyle {
        gap: get-vw(8px);
        grid-gap: get-vw(8px);
      }

      &:hover {
        svg {
          transform: translateX(5px);
        }
      }
    }
  }
}
