@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="blue"] {
  .recommend-services {
    :global(.product-card) {
      background: var(--bg-blue-light-2) !important;
    }
    :global(.product-card_label) {
      background: var(--white);
    }
  }
}

.recommend-services {
  padding: 80px 0;
  @include desktopStyle {
    padding-top: get-vw(80px);
    padding-bottom: get-vw(80px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &.bg-gray {
    padding: 120px 0;
    background: var(--gray-bg);
    @include desktopStyle {
      padding-top: get-vw(120px);
      padding-bottom: get-vw(120px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
    @media only screen and (max-width: $bp-phone) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &_content {
    gap: 32px;
    grid-gap: 32px;
    margin-top: 60px;
    @include desktopStyle {
      gap: get-vw(32px);
      grid-gap: get-vw(32px);
      margin-top: get-vw(60px);
    }
    @media only screen and (max-width: $bp-tablet) {
      gap: 12px;
      grid-gap: 12px;
      margin-top: 32px;
    }
    :global(.product-card) {
      width: calc(25% - 24px);
      height: auto;
      @include desktopStyle {
        width: calc(25% - get-vw(24px));
      }
      @media only screen and (max-width: $bp-tablet) {
        width: calc(33.333% - 8px);
      }
      @media only screen and (max-width: $bp-phone) {
        width: 100%;
      }
    }
  }
}
