@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
@import "@/app/assets/style/variables";

.document-groups {
  padding: 120px 0;
  @include desktopStyle {
    padding-top: get-vw(120px);
    padding-bottom: get-vw(120px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media only screen and (max-width: $bp-phone) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &_title {
    margin-bottom: 90px;
    @include desktopStyle {
      margin-bottom: get-vw(90px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-bottom: 32px;
    }
  }

  &_wrapper {
    @media only screen and (max-width: $bp-tablet) {
      display: block;
    }
  }

  &_sidebar {
    flex: 0 1 590px;
    padding-right: 32px;
    @include desktopStyle {
      flex-basis: get-vw(590px);
      padding-right: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-right: 0;
    }

    &-search {
      position: relative;
      margin-bottom: 32px;
      @include desktopStyle {
        margin-bottom: get-vw(32px);
      }

      svg {
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -12px;
        width: 24px;
        height: 24px;
        color: var(--gray-light);
        @include desktopStyle {
          margin-top: get-vw(-12px);
          width: get-vw(24px);
          height: get-vw(24px);
        }
        @media only screen and (max-width: $bp-tablet) {
          margin-top: -10px;
          width: 20px;
          height: 20px;
        }
      }
      input {
        width: 100%;
        color: var(--gray-dark);
        font-family: $font-main;
        font-size: 2.4rem;
        font-weight: 600;
        border: none;
        padding: 3px 0 3px 36px;
        outline: none;
        background: none;
        @include desktopStyle {
          padding: get-vw(3px) 0 get-vw(3px) get-vw(36px);
        }
        @media only screen and (max-width: $bp-tablet) {
          font-size: 1.6rem;
          padding: 1px 0 1px 32px;
        }
        &::placeholder {
          color: var(--gray-light);
        }
      }
    }

    &-categories {
      @media only screen and (max-width: $bp-tablet) {
        display: none;
      }

      &-item {
        &:not(:last-child) {
          margin-bottom: 24px;
          @include desktopStyle {
            margin-bottom: get-vw(24px);
          }
        }

        button {
          background: none;
          border: none;
          padding: 0;
          text-align: left;
          line-height: 1.5;
          cursor: pointer;
          color: inherit;
          transition: color .3s;
          svg {
            margin-left: 16px;
            width: 20px;
            height: 20px;
            opacity: 0;
            transform: translateX(-5px);
            transition: opacity .3s, transform .3s;
            @include desktopStyle {
              margin-left: get-vw(16px);
              width: get-vw(20px);
              height: get-vw(20px);
            }
          }

          &.active,
          &:hover {
            color: var(--blue);
            svg {
              opacity: 1;
              transform: none;
            }
          }
          &:hover {
            span {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  &_content {
    flex: 1;
    &-categories {
      &-title {
        color: var(--gray-dark);
        margin-bottom: 40px;
        @include desktopStyle {
          margin-bottom: get-vw(40px);
        }
        @media only screen and (max-width: $bp-tablet) {
          display: none;
        }
      }
    }

    &-groups {}
  }
}

.documents {
  &_descr {
    font-family: $font-main-MD;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.6;
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.4rem;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
      @include desktopStyle {
        margin-bottom: get-vw(16px);
      }
    }
  }

  &_content {
    gap: 16px;
    grid-gap: 16px;
    @include desktopStyle {
      gap: get-vw(16px);
      grid-gap: get-vw(16px);
    }
    @media only screen and (max-width: $bp-phone) {
      gap: 12px;
      grid-gap: 12px;
    }
    :global(.document-link) {
      width: percent-width-flex-items(2, 16px);
      @include desktopStyle {
        width: percent-width-flex-items(2, get-vw(16px));
      }
      @media only screen and (max-width: $bp-tablet) {
        width: percent-width-flex-items(2, 16px);
      }
      @media only screen and (max-width: $bp-phone) {
        width: 100%;
      }
    }
  }
}
