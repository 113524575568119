@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.feedback {
  padding: 80px 0;
  @include desktopStyle {
    padding-top: get-vw(80px);
    padding-bottom: get-vw(80px);
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 0;
    padding-bottom: 40px;
  }

  &:last-child {
    padding-bottom: 0;
  }

  :global(.select:not(.b-error) .select__control:not(:hover,.select__control--is-focused)),
  :global(.input:not(:hover,:focus,.b-error)),
  :global(.textarea:not(:hover,:focus,.b-error)) {
    border-color: var(--white);
  }

  &_wrapper {
    padding: 120px;
    background: var(--bg-blue-light-2);
    border-radius: 12px;
    @include desktopStyle {
      padding: get-vw(120px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin: 0 -16px;
      padding: 80px 16px;
    }
    @media only screen and (max-width: $bp-phone) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    &.b-has-hash {
      .feedback_contact-value {
        font-size: 2rem;
      }
      @media only screen and (min-width: $bp-tablet + 1) {
        .feedback_left {
          width: 644px;
          @include desktopStyle {
            width: get-vw(644px);
          }
        }
        .feedback_right {
          width: calc(100% - 700px);
          max-width: 637px;
          @include desktopStyle {
            width: calc(100% - get-vw(700px));
            max-width: get-vw(637px);
          }
        }
        .feedback_contact {
          width: 60%;
        }
        .feedback_image {
          width: 34%;
        }
        .feedback_contact-value {
          font-size: 2.4rem;
        }
      }
    }
    @media only screen and (max-width: $bp-tablet) {
      .feedback_contact,
      .feedback_right {
        margin-top: 40px;
      }
    }
  }

  &_left {
    width: 600px;
    @include desktopStyle {
      width: get-vw(600px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
    }
  }

  &_right {
    width: calc(100% - 625px);
    max-width: 637px;
    @include desktopStyle {
      width: calc(100% - get-vw(625px));
      max-width: get-vw(637px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      max-width: none;
      margin-top: 32px;
    }
  }

  &_text,
  &_description {
    margin-top: 32px;
    color: var(--gray);
    @include desktopStyle {
      margin-top: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 24px;
    }
    @media only screen and (max-width: $bp-phone) {
      margin-top: 16px;
    }
  }

  &_text {
    width: 98%;
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
    }
  }

  &_description {
    color: var(--gray);
    font-size: 1.6rem;
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.4rem;
    }
  }

  &_bottom {
    padding-top: 34px;
    margin-top: auto;
    width: 100%;
    @include desktopStyle {
      padding-top: get-vw(34px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-top: 0;
    }
    .feedback_image {
      @media only screen and (max-width: $bp-tablet) {
        display: none;
      }
    }
  }

  &_image {
    width: 45%;
    &.b-mobile {
      width: 100%;
      margin-bottom: 48px;
      display: none;
    }
    @media only screen and (max-width: $bp-tablet) {
      &.b-mobile {
        display: block;
      }
    }
    img {
      display: block;
      margin-left: auto;
      width: auto;
      height: auto;
      @media only screen and (max-width: $bp-tablet) {
        margin-right: auto;
      }
    }

    &--app {
      max-width: 182px;
      margin-bottom: -68px;
      @include desktopStyle {
        max-width: get-vw(182px);
        margin-bottom: get-vw(-68px);
      }
    }
  }

  &_contact {
    width: 54%;
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      margin-top: 16px;
    }
  }

  &_socials {
    &:not(:first-child) {
      margin-top: 32px;
      @include desktopStyle {
        margin-top: get-vw(32px);
      }
    }

    &-title {
      font-family: $font-main-MD;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.6;
      color: var(--gray);
      margin-bottom: 16px;
      @include desktopStyle {
        margin-bottom: get-vw(16px);
      }
      @media only screen and (max-width: $bp-tablet) {
        font-size: 1.5rem;
        margin-bottom: 10px;
      }
    }

    &-list {
      gap: 12px;
      grid-gap: 12px;
      @include desktopStyle {
        gap: get-vw(12px);
        grid-gap: get-vw(12px);
      }
      @media only screen and (max-width: $bp-tablet) {
        gap: 16px;
        grid-gap: 16px;
      }

      &-item {
        @media only screen and (max-width: $bp-tablet) {
          padding: 12px;
          border-radius: 8px;
          background: var(--bg-blue-light-4);
        }

        img {
          width: 24px;
          height: 24px;
          @include desktopStyle {
            width: get-vw(24px);
            height: get-vw(24px);
          }
        }
      }
      &-value {
        line-height: 1.5;
        a {
          color: inherit;
          text-decoration: none;
        }
      }

      &--item-square {
        gap: 16px;
        grid-gap: 16px;
        @include desktopStyle {
          gap: get-vw(16px);
          grid-gap: get-vw(16px);
        }

        .feedback_socials-list-item {
          padding: 12px;
          border-radius: 8px;
          background: var(--bg-blue-light-4);
          @include desktopStyle {
            padding: get-vw(12px);
          }
        }
      }
    }
  }

  &_contact-item {
    margin-bottom: 16px;
    gap: 10px 16px;
    grid-gap: 10px 16px;
    @media only screen and (max-width: $bp-tablet) {
      margin-bottom: 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &_contact-label {
    font-size: 1.6rem;
    color: var(--gray);
    width: 100%;
    @media only screen and (max-width: $bp-phone) {
      color: var(--gray-light);
      font-size: 1.5rem;
    }
  }

  &_contact-value {
    display: inline-flex;
    align-items: center;
    font-family: $font-main-SB;
    gap: 8px;
    grid-gap: 8px;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 3.2rem;
    line-height: 1.3;
    color: var(--gray-dark);
    text-decoration: none;
    @media only screen and (max-width: $bp-tablet) {
      font-size: 2.8rem;
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 2.6rem;
    }
    &:hover {
      color: var(--blue);
    }
  }

  &_contact-icon {
    color: var(--blue);
    min-width: 24px;
    @include desktopStyle {
      min-width: get-vw(24px);
      width: get-vw(24px);
      height: get-vw(24px);
    }
  }

  &_contact-hash {
    color: var(--gray-light);
    font-size: 2.4rem;
    line-height: 1.5;
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.6rem;
    }
  }
}