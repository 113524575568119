@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.contacts {
  padding: 80px 0;
  @include desktopStyle {
    padding-top: get-vw(80px);
    padding-bottom: get-vw(80px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &_title {
    margin-bottom: 60px;
    @include desktopStyle {
      margin-bottom: get-vw(60px);
    }
  }

  &_content {
    gap: 12px;
    grid-gap: 12px;
    @include desktopStyle {
      gap: get-vw(12px);
      grid-gap: get-vw(12px);
    }
    @media only screen and (max-width: $bp-phone) {
      display: block;
    }
  }

  &_group {
    border-radius: 12px;
    background: var(--gray-bg);
    padding: 40px;
    flex: 1 0 percent-width-flex-items(3, 12px);
    @include desktopStyle {
      padding: get-vw(40px);
      flex-basis: percent-width-flex-items(3, get-vw(12px));
    }
    @media only screen and (max-width: $bp-tablet) {
      flex-basis: percent-width-flex-items(2, 12px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 32px;
    }

    &:not(:last-child) {
      @media only screen and (max-width: $bp-phone) {
        margin-bottom: 12px;
      }
    }

    &-title {
      line-height: 1.5;
      &:not(:last-child) {
        margin-bottom: 32px;
        @include desktopStyle {
          margin-bottom: get-vw(32px);
        }
        @media only screen and (max-width: $bp-tablet) {
          margin-bottom: 20px;
        }
      }
    }
  }

  // &_items {}

  &_item {
    &:not(:last-child) {
      margin-bottom: 12px;
      @include desktopStyle {
        margin-bottom: get-vw(12px);
      }
    }

    &-value,
    &-map {
      display: block;
    }

    &-wr {
      color: var(--gray);
      text-decoration: none;
      gap: 12px;
      grid-gap: 12px;
      @include desktopStyle {
        gap: get-vw(12px);
        grid-gap: get-vw(12px);
      }
    }

    &-icon {
      color: var(--gray-light);
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin-top: 4px;
      @include desktopStyle {
        width: get-vw(24px);
        height: get-vw(24px);
        margin-top: get-vw(4px);
      }
      @media only screen and (max-width: $bp-tablet) {
        width: 20px;
        height: 20px;
      }
      @media only screen and (max-width: $bp-phone) {
        width: 18px;
        height: 18px;
        margin-top: 2px;
      }
    }
    // &-right {}
    &-value {
      word-break: break-word;
    }

    // &-map {}

    :global(.link-arrow) {
      color: var(--blue); // var(--green)
      gap: 8px;
      grid-gap: 8px;
      font-size: 1.4rem;
      margin-top: 8px;
      @include desktopStyle {
        gap: get-vw(8px);
        grid-gap: get-vw(8px);
        margin-top: get-vw(8px);
      }

      svg {
        width: 12px;
        height: 12px;
        @include desktopStyle {
          width: get-vw(12px);
          height: get-vw(12px);
        }
      }

      &:hover {
        svg {
          transform: translateX(5px);
        }
      }
    }

    &.is-phone,
    &.is-email {
      color: var(--blue); // var(--green)
      .contacts_item-wr,
      .contacts_item-icon {
        color: inherit;
      }
    }
    // &.is-location {}
    // &.is-info {}
    // &.is-schedule {}
  }
}
