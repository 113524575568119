@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="whiteBlack"],
[data-color-scheme="blackWhite"] {
  .seo-text__text:after {
      filter: none;
  }
}

.seo-text__text {
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: linear-gradient(rgba(var(--white-rgba-2), 0) 20%, rgba(var(--white-rgba-2), 0.8));
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
    pointer-events: none;
  }

  &:global(:not(.rah-static--height-auto)) {
    &:after {
      opacity: 1;
    }
  }
}

.seo-text__btn {
  margin-top: 32px;
  @include desktopStyle {
    margin-top: get-vw(32px);
  }
  @media only screen and (max-width: $bp-tablet) {
    margin-top: 24px;
  }
}
