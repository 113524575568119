@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
@import "@/app/assets/style/variables";

.product-info {

  &_wrapper {
    border-radius: 12px;
    overflow: hidden;
    $colors:
      "blue" var(--bg-blue-light-2) var(--bg-blue-light),
      "green" var(--bg-green-light) var(--bg-green);
    // filter: invert(56%) sepia(40%) saturate(522%) hue-rotate(85deg) brightness(92%) contrast(90%);
    @each $name, $bg, $bgBlock in $colors {
      &.bg-#{$name} {
        background: $bg;
        .product-info_image:after {
          background: $bgBlock;
        }
      }
    }
  }
  
  &_content {
    padding: 120px 0 120px 120px;
    width: 54%;
    color: var(--gray);
    :global {
      ul {
        & > li {
          position: relative;
          padding-left: 32px;
          padding-bottom: 20px;
          @include desktopStyle {
            padding-left: get-vw(32px);
            padding-bottom: get-vw(20px);
          }
          &:last-child {
            padding-bottom: 0;
          }
          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 4px;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url('~~/app/assets/images/icons/check-circle.svg');
            background-size: contain;
            width: 20px;
            height: 20px;
            @include desktopStyle {
              width: get-vw(20px);
              height: get-vw(20px);
            }
          }
        }
      }
    }
  }

  &_image {
    width: 46%;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 602px;
      z-index: 0;
    }
    img {
      position: relative;
      z-index: 1;
      display: block;
      max-height: 100%;
    }
  }

  &_text,
  &_description {
    max-width: 600px;
    margin-top: 32px;
  }

  &_description {
    font-size: 1.6rem;
  }

  &_btn {
    margin-top: 32px;
  }
}
