@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.accordion {
  padding: 32px 0;
  border-top: 1px solid var(--gray-second);
  color: var(--gray-dark);
  @include desktopStyle {
    padding-top: get-vw(32px);
    padding-bottom: get-vw(32px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  @media only screen and (max-width: $bp-phone) {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  &:last-child {
    border-bottom: 1px solid var(--gray-second);
  }

  &_top {
    cursor: pointer;
    position: relative;
    padding-right: 64px;
    font-family: $font-main-SB;
    font-size: 2.4rem;
    line-height: 1.5;
    transition: all 0.35s;
    @include desktopStyle {
      padding-right: get-vw(64px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.5rem;
      line-height: 1.6;
    }
    @media only screen and (max-width: $bp-phone) {
      padding-right: 60px;
      min-height: 35px;
    }
    &:hover {
      color: var(--blue);
      .accordion_btn {
        &:before,
        &:after {
         background: var(--blue); 
        }
      }
    }
  }

  &_btn {
    display: block;
    padding: 0;
    border: none;
    position: absolute;
    right: 0;
    top: -2px;
    background: transparent;
    outline: none;
    width: 40px;
    height: 40px;
    transition: all 0.3s;
    @include desktopStyle {
      top: get-vw(-2px);
      width: get-vw(40px);
      height: get-vw(40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      top: -4px;
    }
    @media only screen and (max-width: $bp-phone) {
      width: 36px;
      height: 36px;
      top: -1px;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      background: var(--gray-light);
      left: 50%;
      top: 50%;
      transition: all 0.35s;
    }
    &:before {
      width: 2px;
      height: 16px;
      margin-left: -1px;
      margin-top: -8px;
      @include desktopStyle {
        height: get-vw(16px);
        margin-top: get-vw(-8px);
      }
    }
    &:after {
      width: 16px;
      height: 2px;
      margin-left: -8px;
      margin-top: -1px;
      @include desktopStyle {
        width: get-vw(16px);
        margin-left: get-vw(-8px);
      }
    }
  }

  &_content {
    overflow: hidden;
    transition: all 0.3s;
  }

  &_inner {
    padding-top: 28px;
    color: var(--gray);
    @include desktopStyle {
      padding-top: get-vw(28px);
    }
    @media only screen and (max-width: $bp-phone) {
      padding-top: 24px;
      padding-bottom: 18px;
    }
  }


  &.b-open {
    & > .accordion_top .accordion_btn {
      transform: rotate(180deg);
      &:before {
        opacity: 0;
      }
    }
  }

  &.is-category {
    @media only screen and (min-width: $bp-tablet + 1) {
      padding: 0;
      border: none;
      &:not(.b-open) {
        display: none;
      }

      & > .accordion_top {
        display: none;
      }
      & > .accordion_content {
        height: auto !important;
        overflow: visible;
        transition: none;
        & > .accordion_inner {
          padding: 0;
        }
      }
    }

    & > .accordion_top {
      font-size: 2rem;
      line-height: 1.3;

      .accordion_btn {
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background: url('~~/app/assets/images/icons/arrow-with-circle.svg') center center no-repeat;
        background-size: contain;
        &:before,
        &:after {
          content: none;
        }
      }
    }

    &.b-open {
      & > .accordion_top .accordion_btn {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &:not(.is-category) {
    @media only screen and (max-width: $bp-tablet) {
      border: none;
    }
  }
}