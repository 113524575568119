@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.form-builder {
  &_inputs {
    gap: 24px;
    grid-gap: 24px;
    @include desktopStyle {
      gap: get-vw(24px);
      grid-gap: get-vw(24px);
    }
    @media only screen and (max-width: $bp-tablet) {
      gap: 12px;
      grid-gap: 12px;
    }

    &-row {
      flex: 1 1 100%;
      &.width-50 {
        flex-basis: percent-width-flex-items(2, 24px);
        @include desktopStyle {
          flex-basis: percent-width-flex-items(2, get-vw(24px));
        }
        @media only screen and (max-width: $bp-tablet) {
          flex-basis: 100%;
        }
      }
      &.is-collapsible {
        :global {
          .form_item,
          .form-file,
          .form_checkbox {
            display: none;
          }
        }
      }

      &:not(:first-child) {
        :global {
          .form-file {
            @media only screen and (max-width: $bp-phone) {
              margin-top: 8px;
            }
          }
        }
      }
    }

    :global {
      .form_item {
        margin-bottom: 0;
      }
      .form-file {
        margin-top: 0;
      }
    }

    &.gap_12px {
      gap: 12px;
      grid-gap: 12px;
      @include desktopStyle {
        gap: get-vw(12px);
        grid-gap: get-vw(12px);
      }

      .form-builder_inputs-row {
        &.width-50 {
          flex-basis: percent-width-flex-items(2, 12px);
          @include desktopStyle {
            flex-basis: percent-width-flex-items(2, get-vw(12px));
          }
          @media only screen and (max-width: $bp-tablet) {
            flex-basis: 100%;
          }
        }
      }
    }
  }
  &_bottom {
    margin-top: 32px;
    gap: 32px 24px;
    grid-gap: 32px 24px;
    @include desktopStyle {
      margin-top: get-vw(32px);
      gap: get-vw(32px) get-vw(24px);
      grid-gap: get-vw(32px) get-vw(24px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 24px;
    }
    @media only screen and (max-width: $bp-phone) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &_submit {
    flex-shrink: 0;
    // white-space: nowrap;
    & + .form-builder_success {
      @media only screen and (max-width: $bp-phone) {
        // width: 100%;
        // justify-content: center;
      }
    }
  }
  &_footnote-wr {
    position: relative;
    .form-builder_success {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  &_footnote {
    font-size: 1.4rem;
    color: var(--gray-light);
    max-width: 250px;
    transition: opacity .3s;
    @include desktopStyle {
      max-width: get-vw(250px);
    }

    a {
      color: inherit;
      text-decoration: underline;
      &:hover {
        color: var(--blue);
      }
    }
  }
  &_success {
    color: var(--blue);
    font-family: $font-main-MD;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.6;
    pointer-events: none;
    svg {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      @include desktopStyle {
        width: get-vw(20px);
        height: get-vw(20px);
      }
    }
    span {
      margin-left: 12px;
    }
  }

  &--submit-100 {
    .form-builder_bottom {
      display: block;
    }
    .form-builder_submit {
      width: 100%;
      & + .form-builder_success {
        justify-content: center;
      }
    }
    .form-builder_submit + .form-builder_success,
    .form-builder_footnote-wr {
      margin-top: 32px;
      @include desktopStyle {
        margin-top: get-vw(32px);
      }
    }

    .form-builder_footnote-wr {
      .form-builder_success {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .form-builder_footnote {
      text-align: center;
      margin: 0 auto;
      max-width: 335px;
      @include desktopStyle {
        max-width: get-vw(335px);
        
      }
    }
  }

  &--success {
    .form-builder_footnote {
      opacity: 0;
    }
    .form-builder_success {
      opacity: 1;
    }
  }
}

.collapsible-input {
  display: none;
  &:checked + .collapsible-label {
    margin-bottom: 24px;
    @include desktopStyle {
      margin-bottom: get-vw(24px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-bottom: 12px;
    }

    &:before {
      border-color: var(--blue);
    }
    &:after {
      transform: scale(1);
      opacity: 1;
    }

    // SHOW COLLAPSIBLE INPUT
    & + *:not(:global(.form_checkbox)) {
      display: block;
    }
    & + :global(.form_checkbox) {
      display: inline-block;
    }
  }
  &:global(.b-error) + .collapsible-label {
    color: var(--red);
    &:before {
      border-color: var(--red);
    }
  }
}

.collapsible-label {
  font-family: $font-main-SB;
  color: var(--gray);
  font-size: 1.6rem;
  line-height: 1.3;
  position: relative;
  display: block;
  padding-left: 32px;
  cursor: pointer;
  @include desktopStyle {
    padding-left: get-vw(32px);
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    transition: all 0.3s;
  }
  &:before {
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: var(--white);
    border-radius: 6px;
    border: 1px solid var(--gray-second);
    @include desktopStyle {
      width: get-vw(20px);
      height: get-vw(20px);
      border-radius: get-vw(6px);
    }
  }
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 3L4.5 8.5L2 6' stroke='%23499BD5' stroke-width='1.6666' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    opacity: 0;
    transform: scale(0.75);
    @include desktopStyle {
      top: get-vw(4px);
      left: get-vw(4px);
      width: get-vw(12px);
      height: get-vw(12px);
    }
  }

  &:hover {
    &:before {
      border-color: var(--blue);
      background: #F3F7FF;
    }
  }
}