@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.rich-text_wrapper {
  margin: 80px 0;
  overflow-wrap: break-word;
  @include desktopStyle {
    margin-top: get-vw(80px);
    margin-bottom: get-vw(80px);
  }
  @media only screen and (max-width: $bp-tablet) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  &::last-child {
    margin-bottom: 0;
  }
}