@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.pagination {
  margin-top: 60px;
  text-align: center;
  @include desktopStyle {
    margin-top: get-vw(60px);
  }
  @media only screen and (max-width: $bp-tablet) {
    margin-top: 32px;
  }

  &_btn-load-more {
    margin-bottom: 32px;
    display: none;
    @include desktopStyle {
      margin-top: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      display: inline-flex;
    }
  }

  &_actions {
    gap: 32px;
    grid-gap: 32px;
    @include desktopStyle {
      gap: get-vw(32px);
      grid-gap: get-vw(32px);
    } 
  }

  &_btn {
    width: 24px;
    height: 24px;
    background: transparent;
    padding: 0;
    border: none;
    transition: all 0.3s;
    cursor: pointer;
    color: var(--gray-dark);
    @include desktopStyle {
      width: get-vw(24px);
      height: get-vw(24px);
    }
    @media only screen and (min-width: $bp-tablet + 1) {
      &:not([disabled]):hover {
        color: var(--blue);
      }
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 20px;
      height: 20px;
    }
    &[disabled] {
      opacity: 0.2;
      cursor: no-drop;
    }
    svg {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
  }

  &_list {
    gap: 10px 14px;
    grid-gap: 10px 14px;
    @include desktopStyle {
      gap: get-vw(10px) get-vw(14px);
      grid-gap: get-vw(10px) get-vw(14px);
    }
    @media only screen and (max-width: $bp-tablet) {
      gap: 8px 2px;
      grid-gap: 8px 2px;
    }
  }

  &_item {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s;
    color: var(--gray);
    @include desktopStyle {
      width: get-vw(40px);
      height: get-vw(40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 32px;
      height: 32px;
    }
    &:hover {
      color: var(--blue);
    }
    &.b-selected {
      color: var(--white);
      background: var(--blue);
    }
  }
}