@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.form-file {
  margin-top: 24px;
  line-height: 1;
  @include desktopStyle {
    margin-top: get-vw(24px);
  }
  @media only screen and (max-width: $bp-phone) {
    margin-top: 20px;
  }
  &.b-error {
    .form-file_label {
      color: var(--red);
    }
  }

  &_input {
    display: none;
  }

  &_label {
    cursor: pointer;
    color: var(--gray);
    font-family: $font-main-SB;
    font-size: 1.8rem;
    line-height: 1.3;
    transition: color 0.3s;
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.6rem;
    }
    &:hover {
      color: var(--blue);
    }
  }

  &_name {
    color: var(--gray);
    font-family: $font-main-SB;
    font-size: 1.8rem;
    line-height: 1.3;
    transition: color 0.3s;
    padding-right: 20px;
    display: table;
    position: relative;
    margin-top: 5px;
    @include desktopStyle {
      padding-right: get-vw(20px);
      margin-top: get-vw(5px);
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.6rem;
    }
  }

  &_btn {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    width: 16px;
    height: 16px;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: all 0.3s;
    color: var(--gray);
    @include desktopStyle {
      width: get-vw(16px);
      height: get-vw(16px);
    }
    &:hover {
      color: var(--blue);
    }
  }

  &_label-icon {
    width: 24px;
    height: 24px;
    display: block;
    @include desktopStyle {
      width: get-vw(24px);
      height: get-vw(24px);
    }
    @media only screen and (max-width: $bp-phone) {
      width: 20px;
      height: 20px;
    }
  }

  &_label-text {
    max-width: calc(100% - 24px);
    padding-left: 8px;
    text-decoration: underline;
    @include desktopStyle {
      max-width: calc(100% - get-vw(24px));
      padding-left: get-vw(8px);
    }
    @media only screen and (max-width: $bp-phone) {
      max-width: calc(100% - 20px);
    }
  }
}
