@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.faq {
  padding: 80px 0;
  @include desktopStyle {
    padding-top: get-vw(80px);
    padding-bottom: get-vw(80px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &.bg-gray {
    background: var(--gray-bg);
    padding: 120px 0;
    @include desktopStyle {
      padding-top: get-vw(120px);
      padding-bottom: get-vw(120px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &_left {
    width: 446px;
    @include desktopStyle {
      width: get-vw(446px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 32px;
    }
  }

  &_right {
    width: calc(100% - 446px);
    padding-left: 120px;
    @include desktopStyle {
      width: calc(100% - get-vw(446px));
      padding-left: get-vw(120px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      padding-left: 0;
    }
  }

  &_text {
    font-weight: 500;
    color: var(--gray);
    margin-top: 16px;
    width: 100%;
    @include desktopStyle {
      margin-top: get-vw(16px);
    }
  }

  &_image {
    margin-top: 96px;
    @include desktopStyle {
      margin-top: get-vw(96px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 0;
      margin-bottom: 32px;
    }
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      display: block;
      @media only screen and (max-width: $bp-tablet) {
        margin: 0 auto;
      }
      @media only screen and (max-width: $bp-phone-sm) {
        max-width: 78%;
      }
    }
  }
}