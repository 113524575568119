@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="whiteBlack"] {
  .insurance-products_block {
    background: #E0E0E0 !important;
  }
}
[data-color-scheme="blackWhite"] {
  .insurance-products_block {
    background: var(--gray-dark-3) !important;
  }
}
[data-color-scheme="blue"] {
  .insurance-products_block {
    background: var(--white) !important;
  }
  .insurance-products_link-label {
    background: var(--bg-blue-light-2);
  }
}


.insurance-products {
  padding: 100px 0 120px;
  background: var(--blue-light);
  @include desktopStyle {
    padding-top: get-vw(100px);
    padding-bottom: get-vw(120px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
    &:first-child {
      padding-top: 30px;
    }
  }
  & + div {
    padding-top: 120px;
    @include desktopStyle {
      padding-top: get-vw(120px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-top: 40px;
    }
  }

  &_left {
    width: 425px;
    @include desktopStyle {
      width: get-vw(425px);
    }
    .insurance-products_btn-link {
      display: inline-flex;
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
    }
    .insurance-products_btn-link {
      &:global(.btn.b-with-icon) {
        display: inline-flex;
        @media only screen and (max-width: $bp-phone) {
          display: none;
        }
      }
    }
  }

  &_btn-link {
    &:global(.btn.b-with-icon) {
      margin-top: 32px;
      padding-right: 22px;
      padding-left: 32px;
      display: none;
      @include desktopStyle {
        padding-right: get-vw(22px);
        padding-left: get-vw(32px);
        margin-top: get-vw(32px);
      }
      @media only screen and (max-width: $bp-tablet) {
        margin-top: 24px;
      }
      @media only screen and (max-width: $bp-phone) {
        display: inline-flex;
      }
      svg {
        @include desktopStyle {
          width: get-vw(16px);
          height: get-vw(16px);
        }
      }
    }
  }

  &_right {
    width: calc(100% - 450px);
    max-width: 1113px;
    gap: 16px;
    grid-gap: 16px;
    min-height: 632px;
    @include desktopStyle {
      width: calc(100% - get-vw(450px));
      max-width: get-vw(1113px);
      min-height: get-vw(632px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      margin-top: 32px;
      min-height: 1px;
    }
    @media only screen and (max-width: $bp-phone) {
      flex-direction: column;
    }
  }

  &_text {
    margin-top: 16px;
    @include desktopStyle {
      margin-top: get-vw(16px);
    }
  }

  &_block {
    border-radius: 12px;
    overflow: hidden;
    flex: 1;
    padding: 50px 40px 0;
    transition: flex 0.3s;
    position: relative;
    @include desktopStyle {
      padding: get-vw(50px) get-vw(40px) 0;
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 24px 24px 0;
    }
    @media only screen and (max-width: $bp-phone) {
      width: 100%;
    }
    &.b-active {
      flex: 2.33;
      .insurance-products_links {
        opacity: 1;
        visibility: visible;
        @media only screen and (max-width: $bp-phone) {
          transition:
            all 0.3s,
            max-height 0.7s;
          max-height: 1000px;
        }
      }
      .insurance-products_btn {
        opacity: 1;
        visibility: visible;
      }
      .insurance-products_image {
        transform: translateX(0);
      }
      &::after {
        transform: rotate(180deg);
      }
    }
    $colors:
      "green" var(--bg-green),
      "green_secondary" var(--bg-green-2),
      "green_light" var(--bg-green-light-2),
      "green_dark" var(--bg-green),
      "blue" var(--bg-blue),
      "blue_secondary" var(--bg-blue-2),
      "blue_light" var(--bg-blue-light),
      "violet_light" var(--bg-violet-light),
      "brown" var(--bg-brown),
      "brown_light" var(--bg-brown-light),
      "pink" var(--bg-pink),
      "pink_dark" var(--bg-pink-dark),
      "pink_light" var(--bg-pink-light),
      "yellow" var(--bg-yellow),
      "yellow_light" var(--bg-yellow-light),
      "turquoise" var(--bg-turquoise);
    @each $name, $bgBlock in $colors {
      &.bg-#{$name} {
        background: $bgBlock;
      }
    }
    &::after {
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      background: url("~~/app/assets/images/icons/arrow-with-circle.svg") center center no-repeat;
      background-size: contain;
      top: 24px;
      right: 24px;
      transition: all 0.3s;
      @media only screen and (max-width: $bp-phone) {
        content: "";
      }
    }
    &.is-empty {
      &::after {
        content: none;
      }
      @media only screen and (min-width: $bp-tablet + 1) {
        .insurance-products_image {
          height: 410px;
          @include desktopStyle {
            height: get-vw(410px);
          }
        }
      }
    }
  }

  &_content {
    width: 100%;
    height: 100%;
  }

  &_inner {
    width: 100%;
    height: 100%;
    transition: all 0.2s;
    @media only screen and (max-width: $bp-phone) {
      width: 100% !important;
    }
  }

  &_over-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    display: block;
    text-decoration: none;
    outline: none;
  }

  &_name {
    font-size: 3.2rem;
    line-height: 1.3;
    font-family: $font-main-SB;
    color: var(--gray-dark);
    @media only screen and (max-width: $bp-tablet) {
      font-size: 2.4rem;
    }
    @media only screen and (max-width: $bp-phone) {
      padding-right: 30px;
    }
  }

  &_links {
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
    @media only screen and (max-width: $bp-phone) {
      opacity: 1;
      max-height: 0px;
      overflow: hidden;
    }
  }

  &_link {
    color: var(--gray-dark);
    font-size: 2.2rem;
    line-height: 1.5;
    text-decoration: none;
    display: table;
    margin-bottom: 24px;
    @include desktopStyle {
      margin-bottom: get-vw(24px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.8rem;
    }
    &:first-child {
      margin-top: 24px;
      @include desktopStyle {
        margin-top: get-vw(24px);
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &_link-text {
    text-decoration: underline;
  }

  &_link-label {
    font-family: $font-main-B;
    padding: 4px 13px;
    background: var(--white);
    font-size: 1.2rem;
    text-decoration: none;
    border-radius: 66px;
    font-weight: 700;
    text-transform: uppercase;
    @include desktopStyle {
      padding: get-vw(4px) get-vw(13px);
    }
  }

  &_link-arrow {
    &:hover {
      color: var(--gray-dark);
    }
    svg {
      @include desktopStyle {
        width: get-vw(13px);
        height: get-vw(14px);
      }
    }
  }

  &_image {
    height: 352px;
    width: calc(100% + 80px);
    margin: auto -40px 0;
    transform: translateX(-12%);
    transition: all 0.3s;
    @include desktopStyle {
      width: calc(100% + get-vw(80px));
      height: get-vw(352px);
      margin-left: get-vw(-40px);
      margin-right: get-vw(-40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-left: -24px;
      width: calc(100% + 48px);
      margin-right: -24px;
      height: 30vw;
    }
    @media only screen and (max-width: $bp-phone) {
      transform: none;
      height: 36.5vw;
      margin-top: -6px;
    }
    picture {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: auto;
      display: block;
      max-height: 100%;
      object-fit: cover;
      @media only screen and (max-width: $bp-phone) {
        object-fit: contain;
        object-position: right bottom;
      }
    }
  }

  &_btn {
    position: absolute;
    z-index: 4;
    right: 30px;
    bottom: 30px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    @include desktopStyle {
      right: get-vw(30px);
      bottom: get-vw(30px);
    }
    @media only screen and (max-width: $bp-tablet) {
      right: 24px;
      bottom: 24px;
    }
    @media only screen and (max-width: $bp-phone) {
      opacity: 1;
      visibility: visible;
      right: unset;
      left: 24px;
    }
    &:global(.btn.b-only-icon) {
      width: 72px;
      height: 72px;
      border-radius: 100%;
      @include desktopStyle {
        width: get-vw(72px);
        height: get-vw(72px);
      }
      @media only screen and (max-width: $bp-tablet) {
        width: 40px;
        height: 40px;
      }
    }
    svg {
      @include desktopStyle {
        width: get-vw(24px);
        height: get-vw(24px);
      }
      @media only screen and (max-width: $bp-tablet) {
        width: 16px;
        height: 16px;
      }
    }
  }
}
